.contactMeContainer {
    width: 100%;
    margin: 50px auto;
}

.contactMeListContainer > li {
    text-align: left;
}
.contactMeContainer form {
    /*margin: 20px auto;*/
    text-align: center;
    /*width: 80%;*/
    padding: 20px 0 50px 0;
      box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12)
    ;



  min-height: 200px;
  width: 50vw;
  margin: 50px auto 100px auto;
  background: white;
  border-radius: 5px;

}
.contactMeContainer form > * {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}
#emailbtn {
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
.contactMeList:hover {
    cursor: pointer;
}
@media only screen and (max-width: 768px){
    .contactMeContainer form {
        width: 80vw;
    }
    #emailbtn {
        width: 100%;
    }
}