.bodyContainer{
    width: 90%;
    margin: auto;
}
h2{
    font-size: 35px;
}
.iamMessages .Typewriter__wrapper {
    font-weight: bold;
    font-size: 2rem;
}
.Typewriter {
    display: inline-block;
}
.iamText {
    font-size: 1.75rem;
}
.iamText p{
    font-size: 2rem;
    margin: 0;
}
hr {
    width: 75%;
    opacity: .5;
    height: 2px;
}
.iamMessages {
    display: block;
    color: #5f6262;;
}
.home h1 {
    /* color: #2aa8a8; */
    font-size: 3rem;
}
.details > p, .details > h3 {
    margin-bottom: .7em;
    margin-top: .7em;
}
.repository {
    text-decoration: none;
    color: #2aa8a8;
    display: inline;
}
.details > a {
    font-weight: bold;
    color: #098080;
}
.details > a:visited {
    color: #098080;
}
.resume > a {
    display: block;
    color: #2aa8a8;
    
}
.resume > a:visited {
     color:#098080;
 }
.a:visited {
    /* color: #1d7272; */
}
.details > h3 > a > span{
    font-size: .55rem;
    color: black;
}
.intro{
    width: 80%;
    margin: auto;
    /*display: flex;*/
    /*justify-content: space-evenly;*/
    /*align-items: flex-start;*/
}
.aboutMe {
    text-align: justify;
    /*width: 30%;*/
}
.about-me-texts > p {
    /* letter-spacing: .12rem; */
}

.intro img {
    height: 200px;
    border-radius: 100px;
}
.wrap {
    margin: 0rem 1rem;
    display: inline-block;
}
.wrap > h2 {
    margin-top: 5px;
}
.aboutMe img {
    margin-right: 2rem;
}
.left {
    float: left;
}
.details {
    text-align: left;
    padding-left: 65px;
}
.details p {
    font-weight: bold;
}
.quotes {
    font-size: .8rem;
}

@media only screen and (max-width: 768px){
    .block, .left {
        display: block;
    }
    .left {
        float: none;
        margin: 0;
    }
    .aboutMe img {
        margin: 0 auto;
    }
    .details {
        text-align: left;
        width: fit-content;
        margin: 0 auto;
        padding-left: 0;
    }
    .home h1 {
        padding-right: 20px;
        padding-left: 20px;
    }
    .quotes {
        margin: 0 10px
    }
    .wrap {
        margin: .5rem;
    }
}