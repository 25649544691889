.App {
  width: 100%;
  margin: 0 auto;
}
.bodyContainer{
  width: 90%;
  margin: auto;
}
p, h1, h2, h3, h4, h5, h6 {
  color: #3a3a3a;
}
h1 {
    font-size: 3rem;
}
h2 {
    font-size: 2.5rem;
}
h3 {
    font-size: 1.25rem;
}
body{
  background-color: #fbfbfb;
}
.error{
  color: rgba(255, 0, 0, 0.71);
}
.btn {
  -webkit-border-radius: 11;
  -moz-border-radius: 11;
  border-radius: 11px;
  font-family: Arial;
  color: white;
  font-size: 20px;
  background-color:#667388;
  padding: 10px 20px 10px 20px;
  border: solid #454e5c 2px;
  text-decoration: none;
}


.btn:hover {
  /* background: #27b3b3; */
  background-image: -webkit-linear-gradient(top, #667388, #454e5c);
  background-image: -moz-linear-gradient(top, #667388, #454e5c);
  background-image: -ms-linear-gradient(top, #667388, #454e5c);
  background-image: -o-linear-gradient(top, #667388, #454e5c);
  background-image: linear-gradient(to bottom, #667388, #454e5c);
  text-decoration: none;
}

ul {
  list-style: none;
  margin: auto;
  width: 50%;
  text-align: center;
}

ul>li {
  text-align: justify;
}

.mt-40{
  margin-top: 40px;
}
.mt-20{
  margin-top: 20px;
}
.mt-10{
  margin-top: 10px;
}
.mt-0{
  margin-top: 0px;
}
.mr-10{
  margin-right: 10px;
}
.mb-0{
  margin-bottom: 0px;
}
.pl-10{
  padding-left: 10px;
}
.pl-15{
  padding-left: 15px;
}
.pr-10{
  padding-right: 10px;
}
.fs-5{
  font-size: .75rem;
}


@media only screen and (min-width: 851px) {
  .App {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }

  
}