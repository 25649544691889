.skillsList {
    display: inline-block;
    width: 25%;
    min-width: 240px;
    max-width: 240px;
    margin: 30px;
}
.skillsList li{
    font-size: 1.2rem;
    padding-bottom: 20px ;
}
.skillContainer h2 {
    padding-right: 20px;
    padding-left: 20px;
}
.li-title {
    padding-left: 5px;
}
ul {
    padding-inline-start: 0!important;
}
.skillsListContainer {
    display: flex;
    justify-content: space-evenly;
    flex-flow: row wrap;
}
@media only screen and (max-width: 835px){
    .skillsList {
        display: inline-block;
        width: fit-content;
        margin: 10px 0;
    }
}